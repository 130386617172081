// Copyright 2017 FOCUS Inc.All Rights Reserved.

/**
 * @fileOverview dev_4
 * @author sheny@made-in-china.com
 */

/* Pub Time: November 1, 2017 11:30:17, Version: 1.0.0 */var template=function(){var b={},v=Object.prototype.hasOwnProperty,p=Array.prototype.indexOf,q=Array.prototype.some,r=Array.prototype.filter,s=Array.prototype.map,m={};b.type=function(b){var a,e=/\{\s*\[native\s*code\]\s*\}/i;null===b?a="null":"undefined"===typeof b?a="undefined":(a=Object.prototype.toString.call(b).match(/\w+/g)[1].toLowerCase(),"object"===a&&e.test(b+"")&&(a="function"));return a};b.trim=function(b){return(b+"").replace(/^[\s\u00A0]+|[\s\u00A0]+$/g,"")};b.extend=function a(){var e=
a,c,d;"object"!==b.type(arguments[0])?(c=1,d=!!arguments[0]):(c=0,d=!1);var h=arguments[c]||{};c=[].slice.call(arguments,c+1);for(var i,f;c.length;)if(i=c.shift(),"object"===b.type(i)){var g,j;for(j in i)if(g=i[j],"object"===b.type(g))if(g==window||g==document||"childNodes"in g&&"nextSibling"in g&&"nodeType"in g){if(d||!(j in h))h[j]=g}else if(g.jquery&&/^[\d\.]+$/.test(g.jquery))h[j]=g;else{f=b.type(h[j]);if(!(j in h)||"undefined"===f||"null"===f||d&&("string"===f||"number"===f||"bool"===f))h[j]=
{};"object"===b.type(h[j])&&e(d,h[j],g)}else if(d||!(j in h))h[j]=g}return h};var n=b.each=function(a,e,c){if(null!=a)if([].forEach&&a.forEach===[].forEach)a.forEach(e,c);else if(a.length===+a.length)for(var d=0,h=a.length;d<h&&e.call(c,a[d],d,a)!==m;d++);else for(d in a)if(b.has(a,d)&&e.call(c,a[d],d,a)===m)break};b.has=function(a,e){return v.call(a,e)};b.identity=function(a){return a};var t=b.some=b.any=function(a,e,c){e||(e=b.identity);var d=!1;if(null==a)return d;if(q&&a.some===q)return a.some(e,
c);n(a,function(a,b,f){if(d||(d=e.call(c,a,b,f)))return m});return!!d};b.find=b.detect=function(a,b,c){var d;t(a,function(a,i,f){if(b.call(c,a,i,f))return d=a,!0});return d};b.contains=b.include=function(a,b){return null==a?!1:p&&a.indexOf===p?-1!=a.indexOf(b):t(a,function(a){return a===b})};b.filter=b.select=function(a,b,c){var d=[];if(null==a)return d;if(r&&a.filter===r)return a.filter(b,c);n(a,function(a,i,f){b.call(c,a,i,f)&&(d[d.length]=a)});return d};b.map=b.collect=function(a,b,c){var d=[];
if(null==a)return d;if(s&&a.map===s)return a.map(b,c);n(a,function(a,i,f){d[d.length]=b.call(c,a,i,f)});return d};b.invert=function(a){var e={},c;for(c in a)b.has(a,c)&&(e[a[c]]=c);return e};b.keys=Object.keys||function(a){if(a!==Object(a))throw new TypeError("Invalid object");var e=[],c;for(c in a)b.has(a,c)&&(e[e.length]=c);return e};b.values=function(a){var e=[],c;for(c in a)b.has(a,c)&&e.push(a[c]);return e};b.random=function(a,b){null==b&&(b=a,a=0);return a+Math.floor(Math.random()*(b-a+1))};
var k={escape:{"&":"&amp;","<":"&lt;",">":"&gt;",'"':"&quot;","'":"&#x27;","/":"&#x2F;"}};k.unescape=b.invert(k.escape);var w={escape:RegExp("["+b.keys(k.escape).join("")+"]","g"),unescape:RegExp("("+b.keys(k.unescape).join("|")+")","g")};b.each(["escape","unescape"],function(a){b[a]=function(b){return null==b?"":(""+b).replace(w[a],function(b){return k[a][b]})}});var u={evaluate:/{{([\s\S]+?)}}/g,interpolate:/{{=([\s\S]+?)}}/g,escape:/{{-([\s\S]+?)}}/g},o=/(.)^/,x={"'":"'","\\":"\\","\r":"r","\n":"n",
"\t":"t","\u2028":"u2028","\u2029":"u2029"},y=/\\|'|\r|\n|\t|\u2028|\u2029/g,l=function(a,e,c){var d,c=b.extend(!0,{},u,c),h=RegExp([(c.escape||o).source,(c.interpolate||o).source,(c.evaluate||o).source].join("|")+"|$","g"),i=0,f="__p+='";a.replace(h,function(b,c,d,e,g){f+=a.slice(i,g).replace(y,function(a){return"\\"+x[a]});c&&(f+="'+\n((__t=("+c+"))==null?'':util.escape(__t))+\n'");d&&(f+="'+\n((__t=("+d+"))==null?'':__t)+\n'");e&&(f+="';\n"+e+"\n__p+='");i=g+b.length;return b});f+="';\n";c.variable||
(f="with(obj||{}){\n"+f+"}\n");f="var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};\n"+f+"return __p;\n";try{d=new Function(c.variable||"obj","util",f)}catch(g){throw g.source=f,g;}if(e)return d(e,b);e=function(a){return d.call(this,a,b)};e.source="function("+(c.variable||"obj")+"){\n"+f+"}";return e};l.util=b;l.entities=k;l.settings=u;return l}.call(this);



template.scriptRender = function(type, data, setting) {
    var scripts = document.getElementsByTagName('script');

    try {
        for (var i = 0; i < scripts.length; i++) {
            if (scripts[i].type === type) {
                return template(scripts[i].innerHTML, data, setting);
            }
        }
    } catch (e) {
        window.console && console.warn('Template Trans Error:' + e);
    }
};

template.getScriptData = function(type, target) {
    var result = template.getScriptText(type, target);
    if (result) {
        return JSON.parse(result);
    }
};

template.setScriptData = function(type, data, target) {
    var scripts = document.getElementsByTagName('script');

    try {
        for (var i = 0; i < scripts.length; i++) {
            if (scripts[i].type === type) {
                scripts[i].innerHTML = data;
            }
        }
    } catch (e) {
        window.console && console.warn('Template set Script Data Error:' + e);
    }
};

template.getScriptText = function(type, target) {
    var scripts;

    if (window.jQuery && target instanceof jQuery) {
        target = target.get(0);
    }
    if (target && target.nodeType === 1) {
        scripts = target.getElementsByTagName('script');
    } else {
        scripts = document.getElementsByTagName('script');
    }

    for (var i = 0; i < scripts.length; i++) {
        if (scripts[i].type === type && $.trim(scripts[i].innerHTML)) {
            return scripts[i].innerHTML;
        }
    }
};
